<template>
    <div class="MyCascader">
        <van-field v-model="inputText" clickable readonly v-bind="$attrs" @click-input="showPopup = true">
            <van-icon v-if="!$attrs.disabled && $attrs.clearable && inputText" slot="right-icon" name="clear" @click="onClear" />
        </van-field>
        <van-popup v-model="showPopup" position="bottom" round>
            <van-cascader :field-names="fieldNames"
                          :options="options"
                          :value="inputValue"
                          title="请选择所在地区"
                          @close="showPopup = false"
                          @finish="onFinish"
            />
        </van-popup>
    </div>
</template>

<script>
    import {getAreas} from "@/utils/tools";

    export default {
        name: "MyCascader",
        inheritAttrs: false,
        props: {
            value: Array,
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                showPopup: false,
                inputText: ''
            }
        },
        computed: {
            fieldNames() {
                return this.$attrs['field-names'];
            },
            inputValue() {
                if (this.value.length > 0) {
                    return this.value[this.value.length - 1];
                }
                return '';
            },
            options() {
                return this.$attrs.options;
            }
        },
        watch: {
            value: 'setDefault'
        },
        mounted() {
            this.setDefault();
        },
        methods: {
            // 全部选项选择完毕后，会触发 finish 事件
            onFinish({selectedOptions}) {
                this.showPopup = false;
                let {text, value} = this.fieldNames;
                let inputValue = [];
                let inputText = [];
                selectedOptions.forEach(option => {
                    inputText.push(option[text]);
                    inputValue.push(option[value]);
                });
                this.inputText = inputText.join(' / ');
                this.$emit('input', inputValue);
            },
            onClear() {
                this.inputText = '';
                this.$emit('input', []);
            },
            // 回显默认值
            setDefault() {
                if (this.value && this.value.length > 0) {
                    let code = this.value[this.value.length - 1];
                    // 根据编号获取对应地区文字
                    this.inputText = getAreas(code, this.options);
                } else {
                    this.inputText = '';
                }
            },
        }
    }
</script>

<style scoped>

</style>
